import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

export default ({ data }) => {
  const product = data.airtable.data
  return (
    <Layout>
      <p>
        {product.Brand[0].data.Name} {product.Name}
      </p>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    airtable(fields: { slug: { eq: $slug } }) {
      data {
        Category
        Diameter_mm_calc
        Length_mm_calc
        # Materials
        Name
        Brand {
          data {
            Name
          }
        }
      }
    }
  }
`
